.outerBlock {
  background-color: var(--primary-color);
  height: 88px;
}
.innnerBlock {
  /* border: 1px solid black; */
  height: 100%;
}

.searchContainer {
  display: flex;
  padding: 14px 20px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 2px;
  background: var(--Gray-00, #fff);
  /* box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08); */
  width: 100%;
  border:none;
  outline: none;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: white !important;
}
.nav-link:focus, .nav-link:hover{
  color:var(--primary-color)  !important;
}

.searchIcon {
    display: block;
    margin: auto 0;
    position: absolute;
    right: 0;
    top: 17px;
    margin-right: 10px;
}

@media (max-width: 576px) {
  .searchContainer {
    padding: 7px 10px;
  }
  .searchIcon {
      top: 8px;
  }
}

.imageLogo {
  display: block;
  margin: auto 0;
  width: 100px;
  height: 3.75rem;
  width: 180px;
  object-fit: contain;
}

@media (max-width: 576px) {
  .imageLogo {
    height: 45px;
    width: 150px;
    object-fit: contain;
  }
}