@import url("./assets/fonts/fonts.css");
@import url("./assets/css/common.css");
@import url("./assets/css/colors.css");

html {
  font-size: 16px; /* Base font size */
}

body {
  margin: 0;
  font-family: "Roboto-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto-Medium";
}

span {
  font-family: "Roboto-Regular" !important;
  line-height: 1.5rem;
}

code {
  font-family: "Roboto-Regular", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.app {
  position: relative;
  background-color: white;
  min-height: 100vh;
}

.confirm-btn {
  background-color: #47d7ac;
  width: 180px;
  text-align: center;
  padding: 6px;
  color: white !important;
  text-decoration: none !important;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  border-radius: 20px;
  transition: all 0.25s ease-in-out;
}
.confirm-btn:hover {
  background-color: #00a651;
}

.loader-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: flex;
}
.loader-wrap .loader {
  margin-top: 200px;
  z-index: 10;
  border-top: 1.1em solid rgba(255, 255, 255, 0.3);
  border-right: 1.1em solid rgba(255, 255, 255, 0.3);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.3);
  border-left: 1.1em solid rgb(255, 255, 255);
}

.app-loader {
  padding-top: 150px;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 0.625rem;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid var(--text-black-color);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.alert-wrap {
  position: fixed;
  top: 110px;
  right: 20px;
  width: 330px;
  z-index: 1000;
}
.alert-dismissible .close {
  padding: 0.75rem 1rem;
}
.alert p {
  margin: 0;
  font-size: 0.938rem;
}
.container {
  max-width: "100%";
}

.btn-success {
  background-color: var(--secondary-color) !important;
}

.btn-success:focus {
  box-shadow: 0 0 0 0.25rem var(--primary-color) !important;
}

.btn-outline-success {
  color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  &:hover {
    background-color: var(--secondary-color) !important;
    color: var(--text-white-color) !important;
  }
}

/* Adjust root font size for smaller screens */
@media (max-width: 768px) {
  html {
    font-size: 15px;
  }
}

/* Adjust root font size for larger screens */
@media (min-width: 1400px) {
  html {
    font-size: 18px;
  }
}
