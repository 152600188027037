.error-span {
  color: red;
  font-size: 0.75rem;
}
.required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}
.p-out-stock {
  text-decoration: underline;
  color: red;
}

.contact-us-container, .customer-service-container {
  .pagebuilder-column-group {
    display: flex;
    flex-wrap: wrap;
  }

  .pagebuilder-column {
      h1, h2, h3, h4, h5 {
        text-align: center;
        font-family: 'Roboto-Medium';
      }
  }
}

/*font-weight*/
.fw-500 {
  font-weight: 500;
}

.table-no-border {
  border-width: 0px !important;
}

.table-no-border>:not(:first-child) {
  border-top-width: 0px;
}

.table-no-border>:not(caption)>*>* {
  padding: 0px;
}

.journey-carausel {
  margin-top: 10px;
}

.journey-carausel .card-wrapper img {
  height: 100px;
  width: 120px;
  align-self: center;
  object-fit: contain;
}

.journey-carausel .card-wrapper h5 {
  font-size: 1rem;
}

/* Media queries for larger screens */
@media (max-width: 425px) {
  .journey-carausel .card-wrapper h5 {
    font-size: 0.8rem;
  }
}