/* .contact-banner{
  background-color: rgb(46, 45, 113);
  color: white;
  display: flex;
  padding: 15px 5%;
  justify-content: space-between;
  align-items: center;
}
.left-contact .upper-text{
  font-weight: 500;
}
.right-contact{
  display: flex;
}
.contact-div {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.contact-icon {
  color: black;
  background-color: white;
  padding: 15px;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  margin-right: 10px;
}
.contact-text .upper-text{
  font-size: 13px;
  line-height: 1em;
}
.contact-text a.down-text {
  color: white;
  font-weight: 500;
} */
.footer-wrap-inner {
  padding: 50px 8%;
  padding-bottom: 10px;
}
.connect-banner {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-bottom: 30px;
}
.footer {
  display: flex;
}
.footer-left {
  padding: 50px 0;
  width: 40%;
}
.footer-img {
  width: 200px;
  margin-bottom: 20px;
}
.store-icons {
  display: flex;
  margin-bottom: 30px;
}
.store-icons a {
  width: 100px;
}
.store-icons a:nth-child(1) {
  margin-right: 20px;
}
.social-media-connect {
  color: #333333;
}
.media-text {
  font-weight: 500;
}
.media-icons {
  display: flex;
  font-size: 1.25rem;
}
.media-icons a {
  margin-right: 10px;
  color: #6c7689;
}
.media-icons a:hover,
.media-icons a:focus {
  color: #2e008b;
}

.footer-right {
  display: flex;
  padding: 50px 3%;
  width: 60%;
  justify-content: space-between;
}
.footer-right .header {
  color: #333333;
  font-weight: 500;
}
.column-divs {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.column-divs a {
  color: #6c7689;
  text-decoration: none !important;
  font-size: 0.875rem;
  margin-bottom: 5px;
}
.column-divs a:hover,
.column-divs a:focus {
  color: #2e008b;
}
.subscribe-us .header {
  font-weight: 600;
}
.subscribe-input {
  margin-top: 10px;
  border: 1px solid #47d7ac;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
}
.subscribe-input input {
  border: none;
  outline: none;
  font-size: 0.75rem;
  width: 200px;
  padding: 0 10px;
}
.subscribe-input span {
  background-color: #47d7ac;
  padding: 5px 30px;
  color: white;
}
.copyright-text {
  background-color: #1a1a1a;
  color: white;
  text-align: center;
  font-size: 0.75rem;
  padding: 10px 0;
}
.footer-header {
  background-color: white;
}

.footerBrandContainer {
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
  align-items: flex-start;
  justify-content: center;
}
.footerBrandText {
  color: var(--text-black-color);
  font-family: 'Roboto-Regular';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footerBrandNameContainer {
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  height: 68px;
  color: var(--text-white-color)
}

.anchorText {
  color: green;
  text-decoration: underline;
}

.footerEmailText {
  width: max-content;
  padding-right: 5px;
}

.footerContactWrapper {
  display: flex;
}

.footer-getintouch-btn {
  background-color: var(--primary-color);
}

@media (max-width: 575px) {
  .footerBrandContainer img ~ p {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 992px) {
  .footerContactWrapper {
    display: inline;
  }
}
