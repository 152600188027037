.toast-container {
    position: fixed;
    z-index: 9999;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px
}

.toast-container .toast-text {
    font-size: 1.1rem;
}