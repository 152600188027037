.outerContainer {
  /* border: 1px solid black; */
  margin-top: 10px;
}

.toggleBar {
  justify-content: end;
}

.cusSupportText {
  color: var(--primary-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* border: 1px solid black; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.cusSupportEmailAndPhone {
  color: #212529;
}

.custHelp {
  color: #5f6c72;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  display: inline-flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
}

.rowContainer{
   display: flex;
   /* border:1px solid green; */
   align-items: center;
}