.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #28a745; /* Green background similar to the one in the image */
    color: white;
    padding: 20px;
    text-align: center;
    z-index: 1000;
  }
  
  .cookie-message p {
    margin: 0;
    font-size: 16px;
    font-family: "Roboto-Medium";
  }

  .cookie-message a {
    font-family: "Roboto-Medium";
    color: white;
  }
  
  .cookie-actions {
    margin-top: 10px;
  }
  
  .cookie-actions button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: white;
    color: #28a745;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .cookie-actions button:hover {
    background-color: #d4d4d4;
  }
  