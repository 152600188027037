.contactusTextContainer {
  display: flex;
  flex-direction: column;
  padding: 40px;
  padding-left: 0px;
}

.contactUsText {
  color: var(--primary-color);
  font-family: "Roboto-Bold";
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.questionText {
  color: var(--text-black-color);
  font-family: "Roboto-Regular";
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 30px;
}
.descriptionText {
  color: #495057;
  font-family: "Roboto-Regular";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.quickContactParentContainer {
  border-radius: 2px;
  background: #f2f4f5;
}
.quickContactFormContainer {
  border-radius: 10px;
  border: 0.74px solid #eee;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(1, 105, 56, 0.03),
    2px 10px 22px 0px rgba(1, 105, 56, 0.05),
    3px 2px 5px 0px rgba(1, 105, 56, 0.06);
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.quickContactFormText {
  text-align: center;
  margin-bottom: 30px;
  color: var(--text-black-color);
  text-align: center;
  font-family: "Roboto-Regular";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.GrpContainer {
  /* border: 1px solid blue; */
  margin-bottom: 15px;
}
.submitBtn {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: var(--primary-color);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: inline-flex;
  outline: none;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3rem;
  word-wrap: break-word;
}
.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--primary-color);
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--primary-color);
}

.mobileNumberInput {
  width: 100% !important;
  height: 38px !important;
}

.quickContactButton {
  background-color: var(--primary-color);
}

.quickContactLoader {
  margin-left: 50%;
  position: fixed;
}
