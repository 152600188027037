.btn-primary,
.btn-primary:hover,
.btn-check:active,
.btn-check:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 0.25rem var(--primary-color) !important;
}

/* a:hover {
  color: white;
} */

.nav-link:focus,
.nav-link:hover {
  color: white;
}

.badgeItem {
  position: absolute;
  top: 0;
  right: 0;
}
