@font-face {
    font-family: 'Roboto-Regular'; /* Name of the custom font */
    src: url('./Roboto-Regular.ttf'); /* URL to the font file */
  }
  
@font-face {
  font-family: 'Roboto-Medium'; /* Name of the custom font */
  src: url('./Roboto-Medium.ttf'); /* URL to the font file */
}

@font-face {
  font-family: 'Roboto-Light'; /* Name of the custom font */
  src: url('./Roboto-Light.ttf'); /* URL to the font file */
}

@font-face {
  font-family: 'Roboto-Thin'; /* Name of the custom font */
  src: url('./Roboto-Thin.ttf'); /* URL to the font file */
}

@font-face {
  font-family: 'Roboto-Bold'; /* Name of the custom font */
  src: url('./Roboto-Bold.ttf'); /* URL to the font file */
}

@font-face {
  font-family: 'Roboto-Italic'; /* Name of the custom font */
  src: url('./Roboto-Italic.ttf'); /* URL to the font file */
}

@font-face {
  font-family: 'Roboto-Black'; /* Name of the custom font */
  src: url('./Roboto-Black.ttf'); /* URL to the font file */
}