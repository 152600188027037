
.buttonContainer {
    width: 125px;
    height: 38px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: var(--secondary-color);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    display: inline-flex;
    outline: none;
    cursor: pointer;
  }
  .buttonText {
    color: white;
    font-size: 1rem;
    font-family: 'Roboto-Regular';
    font-weight: 500;
    line-height: 1.3rem;
    word-wrap: break-word;
  }
